import React, { useState, useEffect, Fragment } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { searchLaunched, getDeliveryZonesLaunched, getSellerDeliveryModesLaunched } from './reducer';
import i18n from "../../../i18n";
import { Helmet } from 'react-helmet';
import { graphql } from 'gatsby';
import Layout from '../../components/Layout';
import getStyles from "./styles";
import { useStyles } from "react-styles-hook";
import CoverImage from '../../components/CoverImage';
import signUpMobile from '../../../assets/signupMobile.png';
import signUpDesktop from '../../../assets/signUpDesktop.png';
import NavBarMenu from '../../components/NavBarMenu';
import SellerInfo from '../../components/SellerInfo';
import ProductListCard from '../../components/ProductListCard';
import PriceFilter from '../../components/PriceFilter';
import DropdownSelector from '../../components/DropdownSelector';
import SnackBar from '../../components/SnackBar';
import Loader from '../../components/Loader';
import Paging from '../../components/Paging';
import { Transition } from '@headlessui/react';
import { capitalizeFirst } from '../../utils/format';
import { Pagination } from "../../components/Pagination";
import SellerDeliveryInfo from '../../components/SellerDeliveryInfo';

const SellerPage = ({ data, pageContext, t }) => {

  const dispatch = useDispatch();

  const [isOpenFilter, setIsOpenFilter] = useState(false);

  const sortedFirstLevelCategorySlugs = ["femme", "enfant", "homme", "coaching-capillaire"];
  // i18n used for translation
  i18n(pageContext.locale);

  // for used the style 
  const classes = useStyles(getStyles());

  // retrieve seller information
  const currentInfo = data.currentSeller.edges[0].node
  // retrieve products seller information
  // const currentProduct = data.currentSellerProducts;

  // retrieve if the seller is validated 
  const isAvailable = data.currentSeller.edges[0].node.isAvailable;

  const searchLocale = pageContext.locale;
  const searchSellerName = currentInfo.corporateName;
  const [searchKey, setSearchKey] = useState(null);
  const [searchPage, setSearchPage] = useState(null);
  const [searchPriceMin, setSearchPriceMin] = useState(null);
  const [searchPriceMax, setSearchPriceMax] = useState(null);
  const [searchByCategories, setSearchByCategories] = useState(null);
  const [orderType, setOrderType] = useState(null);

  // reducer
  const { isLoading, productContents, errors, snackBarLoading, snackBarOpen, snackBarMessage, snackBarError, page, lastPage } = useSelector((state) => ({
    isLoading: state.getIn(["sellerPage", "isLoading"]),
    productContents: state.getIn(["sellerPage", "productContents"]),
    errors: state.getIn(["sellerPage", "errors"]),
    snackBarLoading: state.getIn(["sellerPage", "snackBarLoading"]),
    snackBarOpen: state.getIn(["sellerPage", "snackBarOpen"]),
    snackBarMessage: state.getIn(["sellerPage", "snackBarMessage"]),
    snackBarError: state.getIn(["sellerPage", "snackBarError"]),
    page: state.getIn(["sellerPage", "page"]),
    lastPage: state.getIn(["sellerPage", "lastPage"]),
  }));

  const today = new Date();

  // UseEffects used for filtering by prices
  useEffect(() => {
    if (searchPriceMin || searchPriceMax || orderType) {
      setSearchKey("0###" + searchPriceMin + searchPriceMax + orderType + searchByCategories);
    }
  }, [searchPriceMin, searchPriceMax, orderType, searchByCategories]);

  useEffect(() => {
    if (searchPage) {
      setSearchKey(searchPage + "###" + searchPriceMin + searchPriceMax + orderType + searchByCategories);
    }
  }, [searchPage]);

  useEffect(() => {
    if (!searchKey) return;
    const searchPageToRequest = parseInt(searchKey.split("###")[0]);
    if ((searchPriceMin || searchPriceMax || orderType || searchByCategories)) {
      dispatch(searchLaunched({
        searchPage: searchPageToRequest,
        searchSellerName,
        searchLocale,
        searchPriceMin,
        searchPriceMax,
        orderType,
        searchByCategories
      }));
    }
  }, [searchKey]);

  //UseEffects use for setting deliveryZones and sellerDeliveryModes
  useEffect(() => {
    dispatch(getDeliveryZonesLaunched());
    dispatch(getSellerDeliveryModesLaunched(currentInfo.sellerId));
  }, []);

  const currentListMinSearchPrice = 0;
  const currentListMaxSearchPrice = 300;

  // check if offers are available
  let currentProductList = data.currentSellerProducts.edges.filter(({ node: productContent }) => {
    if (productContent.childContentfulFicheProduitProductsFieldJsonNode == null) return false;
    const variants = productContent.childContentfulFicheProduitProductsFieldJsonNode.products || [];

    let productContentHasAtLeastOneAvailableOffer = false;
    variants.map((variant) => {
      (variant.offers || []).forEach((offer) => {
        if (JSON.parse(offer.isAvailable)) {
          productContentHasAtLeastOneAvailableOffer = true;
        }
      });
      return null;
    });

    return productContentHasAtLeastOneAvailableOffer;
    // send products infos to ProductPage
  }).map(({ node: productContent }) => {
    const startDate = productContent.crossedPriceStartDate !== null && productContent.crossedPriceStartDate !== "null" ? new Date(productContent.crossedPriceStartDate) : null;
    const endDate = productContent.crossedPriceEndDate !== null && productContent.crossedPriceEndDate !== "null" ? new Date(productContent.crossedPriceEndDate) : null;
    const defaultVariant = productContent.childContentfulFicheProduitProductsFieldJsonNode.products.filter(variant => variant.offers.some(offer => JSON.parse(offer.isAvailable)))[0];
    const defaultImageId = defaultVariant.images[0];
    const defaultImage = ((data.productImages || {}).edges || [1]).filter(({ node: image }) => image.contentful_id === defaultImageId)[0]?.node || null;
    const defaultOffer = defaultVariant.offers[0];
    const defaultSeller = productContent.seller.corporateName || {};
    const defaultSellerId = productContent.cataloger || {};
    // const defaultSellerId = defaultOffer.sellerId;
    const urlProductPage = `/${pageContext.locale}/produits/${productContent.principalSveCategory.slug}/${productContent.slug}`
    let price = Number(defaultOffer.priceWithVat);
    let crossedPrice = Number(defaultOffer.crossedPriceWithVat);
    if (crossedPrice > 0 || crossedPrice > price && (today <= startDate || today > endDate)) {
      price = Number(defaultOffer.crossedPriceWithVat);
      crossedPrice = null;
    }
    return {
      title: productContent.title,
      id: productContent.childContentfulFicheProduitProductsFieldJsonNode.products[0].id,
      image: defaultImage || null,
      price: price,
      priceDescription: defaultOffer.priceDescription,
      crossedPrice: crossedPrice,
      corporateName: defaultSeller,
      sellerId: defaultSellerId,
      urlProductPage: urlProductPage,
      flapSelectionZawema: JSON.parse(productContent.flapSelectionZawema),
      offerId: defaultOffer.id,
      goal: productContent.attributes.goal,
      porosity: productContent.attributes.porosity,
      careSteps: productContent.attributes.careSteps,
    }
  });    // send products infos to ProductPage by algolia
  if (productContents && productContents?.length !== 0) {
    currentProductList = productContents.map((hit) => {
      const urlProductPage = `/${pageContext.locale}/produits/${hit.categoryPath}/${hit.urlProductPage}`;
      const startDate = hit.crossedPriceStartDate !== null && hit.crossedPriceStartDate !== "null" ? new Date(hit.crossedPriceStartDate) : null;
      const endDate = hit.crossedPriceEndDate !== null && hit.crossedPriceEndDate !== "null" ? new Date(hit.crossedPriceEndDate) : null;
      let price = hit.price;
      let crossedPrice = hit.crossedPriceWithVat;
      if ((startDate !== null || endDate !== null) && crossedPrice > 0 && crossedPrice > price && (today <= startDate || today > endDate)) {
        price = hit.crossedPriceWithVat;
        crossedPrice = null;
      }
      return {
        title: hit.title,
        id: hit.productId,
        image: {
          description: hit.title,
          file: {
            url: hit.imageUrl
          }
        },
        price: price,
        priceDescription: hit.priceDescription,
        crossedPrice: crossedPrice,
        corporateName: hit.sellerName,
        sellerId: hit.sellerId,
        urlProductPage: urlProductPage,
        flapSelectionZawema: hit.flapSelectionZawema,
        offerId: hit.offerId,
        goal: hit.goal,
        porosity: hit.porosity,
        careSteps: hit.careSteps
      }
    });
  }

  // Filters principalSveCategorie to avoid double values in lateral filter section :
  let categories = [];
  let principalSveCategory = data.currentSellerProducts.edges.filter((v, i, a) =>
    i === a.findIndex((t) => (
      t.node.principalSveCategory.slug === v.node.principalSveCategory.slug
    ))
  );
  principalSveCategory.map((partnerCat) => {
    categories.push(partnerCat.node.principalSveCategory)
  })


  // array of checked categories
  const [selectedCategoriesArray] = useState([]);

  const handleClick = (pageNumber) => {
    setSearchPage(pageNumber);
    if (pageNumber === 0) {
      setSearchKey("0###" + searchPriceMin + searchPriceMax + orderType + searchByCategories);
    }
  }

  const initMetas = [{
    name: 'og:url',
    content: `${process.env.GATSBY_WEBSITE_URL}/${pageContext.locale}/seller/${currentInfo.slug}`,
  },
  {
    name: 'title',
    content: `Boutique ${currentInfo.corporateName} sur Zawema.com - Marketplace cheveux texturés`,
  },
  {
    name: 'og:title',
    content: `Boutique ${currentInfo.corporateName} sur Zawema.com - Marketplace cheveux texturés`,
  },
  {
    name: 'description',
    content: currentInfo.companyAbout.companyAbout || "",
  },
  {
    name: 'og:description',
    content: currentInfo.companyAbout.companyAbout || "",
  }];

  const [numberOfCategorie, setNumberOfCategorie] = useState(5);

  return (
    <Layout
      lang={pageContext.locale}
      firstLevelCategories={data.menuFirstLevel.edges.sort((firstLevelCategory1, firstLevelCategory2) => {
        return (
          sortedFirstLevelCategorySlugs.indexOf(firstLevelCategory1.node.slug) - sortedFirstLevelCategorySlugs.indexOf(firstLevelCategory2.node.slug)
        )
      })}
      secondLevelCategories={data.menuSecondLevel.edges}
      thirdLevelCategories={data.menuThirdLevel.edges}
    >
      {/* SEO */}
      <Helmet>
        <html lang="fr" />
        <title>{currentInfo.corporateName} sur Zawema</title>
        {initMetas && initMetas.map(meta => (
          <meta key={meta.name} name={meta.name} content={meta.content} />
        ))}
      </Helmet>

      {/* Filtering section in mobile view */}
      <Transition
        show={isOpenFilter}
        as={Fragment}
        enter="transition-opacity duration-150 transform"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="transition-opacity duration-150 transform"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <div className="w-full absolute top-0 bg-white z-50">
          <div className="w-full h-screen flex flex-col sticky bottom-0 pb-10">
            <div className="w-full p-6 flex justify-end">
              <button
                className='bg-orange-dark'
                onClick={(e) => setIsOpenFilter(!isOpenFilter)}
              >
                <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="white">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                </svg>
              </button>
            </div>
            <div className={"flex flex-wrap"} style={classes.filteringSection}>
              <div className={"w-5/6 h-full pl-5 z-40 pb-8"}>
                <h3 className="font-medium text-semi">Trier par :</h3>
                <DropdownSelector
                  increasingPrice={() => { setOrderType("asc-price") }}
                  decreasingPrice={() => { setOrderType("desc-price") }}
                  decreasingWeight={() => { setOrderType("desc-weight") }}
                  orderType={orderType}
                />
              </div>
              {/* Categories navigation checkboxes */}
              <div className={"w-full mx-auto md:mx-0 pb-14 z-30"}>
                <h3 className="font-medium text-semi text-left my-4 pl-5">Affiner la <span className="font-medium text-semi text-orange-dark">catégorie</span></h3>
                {categories.map((label, index) => (
                  (index < numberOfCategorie) && (
                    <div className="pl-5 my-8" >
                      <fieldset className="space-y-5">
                        <div className="relative flex items-start">
                          <div className="flex items-center h-5 pt-3">
                            <input
                              id={`category-${label.categoryName}`}
                              aria-describedby={label.categoryName}
                              name={label.slug}
                              type="checkbox"
                              style={classes.checkbox}
                              checked={selectedCategoriesArray.includes(label.slug)}
                              className="w-5 h-5 text-orange-dark bg-orange-dark"
                              onChange={(el) => {
                                // create an array of selected sellers when checkbox is checked
                                const checkedCategorieNameValue = el.target.name;
                                selectedCategoriesArray.includes(checkedCategorieNameValue) ?
                                  selectedCategoriesArray.splice(selectedCategoriesArray.indexOf(checkedCategorieNameValue), 1) :
                                  selectedCategoriesArray.push(checkedCategorieNameValue);
                                setSearchByCategories([...selectedCategoriesArray]);
                              }}
                            />
                          </div>
                          <div className="ml-3 text-sm">
                            <label htmlFor={`category-${label.categoryName}`} className="text-black">
                              {capitalizeFirst(label.categoryName)}
                            </label>
                          </div>
                        </div>
                      </fieldset>
                    </div>)
                ))}
                {(numberOfCategorie < categories.length) ?
                  <button className="pl-10 text-sm md:text-xs text-orange-dark" onClick={() => setNumberOfCategorie(numberOfCategorie + 5)}>Voir plus</button>
                  :
                  null
                }
              </div>
              {/* This filter uses Algolia */}
              <div className={"w-5/6 h-full mx-auto pb-14 z-0"}>
                <PriceFilter
                  initValues={[currentListMinSearchPrice, currentListMaxSearchPrice]}
                  onPriceChange={([min, max]) => {
                    setSearchPriceMin(min);
                    setSearchPriceMax(max);
                  }}
                  minimumPrice={searchPriceMin}
                  maxPrice={searchPriceMax}
                />
              </div>
            </div>
            <div className='w-full mb-10 flex justify-center'>
              <button
                className="w-1/2 bg-orange-dark shadow-lg py-2 font-bold text-base"
                onClick={() => setIsOpenFilter(!isOpenFilter)}
              >
                Valider
              </button>
            </div>
          </div>
        </div>
      </Transition>

      {!isOpenFilter
        && <>
          <NavBarMenu
            firstLevelCategories={data.menuFirstLevel.edges.sort((firstLevelCategory1, firstLevelCategory2) => {
              return (
                sortedFirstLevelCategorySlugs.indexOf(firstLevelCategory1.node.slug) - sortedFirstLevelCategorySlugs.indexOf(firstLevelCategory2.node.slug)
              )
            })}
            secondLevelCategories={data.menuSecondLevel.edges}
            thirdLevelCategories={data.menuThirdLevel.edges}
            lang={pageContext.locale}
          />
          <CoverImage
            imageDesktop={signUpDesktop}
            imageMobile={signUpMobile}
            title={currentInfo.corporateName}
            altDesktop="palm trees"
            altMobile="palm trees"
          />
          {/* Container for filtering and results */}
          {isAvailable &&
            <div className="flex pb-14">
              {/* Filtering section on left side's page */}
              <div className="w-full hidden md:flex flex-col md:w-3/12" style={classes.filtersContainer}>
                <div className={"flex flex-wrap pl-5"} style={classes.filteringSection}>
                  <div className={"w-full h-full py-6 z-40 pb-14"}>
                    <h3 className="font-medium text-semi">Trier par :</h3>
                    <DropdownSelector
                      increasingPrice={() => { setOrderType("asc-price") }}
                      decreasingPrice={() => { setOrderType("desc-price") }}
                      decreasingWeight={() => { setOrderType("desc-weight") }}
                      orderType={orderType}
                    />
                  </div>
                  {/* Categories navigation checkboxes */}
                  <div className={"w-full mx-auto md:mx-0 pb-14 z-30"}>
                    <h3 className="font-medium text-semi">Affiner la <span className="font-medium text-semi text-orange-dark">catégorie</span></h3>
                    {categories.map((label, index) => (
                      (index <= numberOfCategorie) && (
                        <div className="pl-8 my-4" >
                          <fieldset className="space-y-5">
                            <div className="relative flex items-start">
                              <div className="flex items-center h-5 pt-3">
                                <input
                                  id={`category-${label.categoryName}`}
                                  aria-describedby={label.categoryName}
                                  name={label.slug}
                                  type="checkbox"
                                  style={classes.checkbox}
                                  checked={selectedCategoriesArray.includes(label.slug)}
                                  className="w-5 h-5 text-orange-dark bg-orange-dark"
                                  onChange={(el) => {
                                    // create an array of selected categories when checkbox is checked
                                    const checkedCategorieNameValue = el.target.name;
                                    selectedCategoriesArray.includes(checkedCategorieNameValue) ?
                                      selectedCategoriesArray.splice(selectedCategoriesArray.indexOf(checkedCategorieNameValue), 1) :
                                      selectedCategoriesArray.push(checkedCategorieNameValue);
                                    setSearchByCategories([...selectedCategoriesArray]);
                                  }}
                                />
                              </div>
                              <div className="ml-2.5 text-xs lg:text-sm">
                                <label htmlFor={`category-${label.categoryName}`} className="text-black">
                                  {capitalizeFirst(label.categoryName)}
                                </label>
                              </div>
                            </div>
                          </fieldset>
                        </div>)
                    ))}
                    {(numberOfCategorie < categories.length) ?
                      <button className="pl-8 text-xs text-orange-dark" onClick={() => setNumberOfCategorie(numberOfCategorie + 5)}>Voir plus</button>
                      :
                      null
                    }
                  </div>
                  {/* This filter uses Algolia */}
                  <div className={"w-5/6 h-full mx-auto md:mx-0 pb-14 z-0 "}>
                    <PriceFilter
                      initValues={[currentListMinSearchPrice, currentListMaxSearchPrice]}
                      onPriceChange={([min, max]) => {
                        setSearchPriceMin(min);
                        setSearchPriceMax(max);
                      }}
                      minimumPrice={searchPriceMin}
                      maxPrice={searchPriceMax}
                    />
                  </div>
                </div>
              </div>

              {/* Results section with products cards on right side of the page */}
              <div className={"flex flex-col w-full md:w-9/12 mb-12"}>
                <div className={"pt-8 pl-2 2xl:pl-0 grid grid-cols-2 gap-x-4 gap-y-8 xl:grid-cols-3 2xl:grid-cols-4"}>
                  {isLoading && (
                    <Loader />
                  )}
                  {!isLoading && (productContents ? productContents?.length !== 0 : true) && currentProductList.map((currentProductListItem) => {
                    return (
                      <ProductListCard product={currentProductListItem} />
                    )
                  })}
                </div>
                {!isLoading && (currentProductList.length === 0 || productContents?.length === 0) && (
                  <div className="mt-4 text-center">
                    <p>Aucun résultat</p>
                  </div>
                )}

                <Pagination
                  page={page}
                  handleClick={(page) => handleClick(page)}
                  lastPage={lastPage}
                />
              </div>
              {/* Filter bottom in mobile version */}
              <div className='w-full flex justify-center fixed bottom-8'>
                <button
                  className='md:hidden rounded-full bg-black text-white flex justify-center items-center py-2 px-4'
                  onClick={() => setIsOpenFilter(!isOpenFilter)}
                >
                  Filtres
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 ml-2" viewBox="0 0 20 20" fill="currentColor">
                    <path fillRule="evenodd" d="M3 3a1 1 0 011-1h12a1 1 0 011 1v3a1 1 0 01-.293.707L12 11.414V15a1 1 0 01-.293.707l-2 2A1 1 0 018 17v-5.586L3.293 6.707A1 1 0 013 6V3z" clipRule="evenodd" />
                  </svg>
                </button>
              </div>
            </div>
          }
          {/* Container for seller infos */}
          {isAvailable &&
            <div className="border-t border-grey-light">
              <div className="px-2 py-6 md:py-10 md:px-6 max-w-screen-2xl mx-auto">
                <SellerDeliveryInfo />
                <SellerInfo
                  seller={currentInfo}
                />
              </div>
            </div>
          }
          {!isAvailable &&
            <div class="text-center">
              <h2 className='text-2xl py-14 text-gray-600'>Aucun produit n'est disponible chez ce vendeur actuellement.</h2>
            </div>
          }
        </>
      }

      {!snackBarLoading && <SnackBar open={snackBarOpen} error={snackBarError} message={snackBarMessage} />}

    </Layout>
  );
};

export default SellerPage;

export const query = graphql`
    query($slug: String!, $locale: String!, $sellerId: String!) {
        menuFirstLevel: allContentfulCategorySve(filter: { slug: { in: ["femme", "enfant", "homme", "coaching-capillaire"] }, node_locale: {eq: $locale}}) {
            edges {
              node {
                slug
                categoryName
              }
            }
          }
        menuSecondLevel: allContentfulCategorySve(filter: {parentCategory: { slug: { in: ["femme", "enfant", "homme", "coaching-capillaire"] }, node_locale: {eq: $locale}}}
        sort : {fields: [slug], order: ASC}) {
            edges {
              node {
                slug
                categoryName
                categoryEncartTitle
                parentCategory {
                  slug
                  categoryName
                  categoryEncartTitle
                }
              }
            }
          }
        menuThirdLevel: allContentfulCategorySve(filter: {parentCategory: {parentCategory: { slug: { in: ["femme", "enfant", "homme", "coaching-capillaire"] }, node_locale: {eq: $locale}}}}
        sort : {fields: [slug], order: ASC}) {
            edges {
              node {
                slug
                categoryName
                categoryEncartTitle
                parentCategory {
                  slug
                  categoryName
                  categoryEncartTitle
                  parentCategory {
                    slug
                    categoryName
                    categoryEncartTitle
                  }
                } 
              }
            }
          }
        currentSeller: allContentfulSellerModel(filter: {slug: {eq: $slug}, node_locale: {eq: $locale}}) {
          edges {
            node {
              node_locale
              slug
              address
              companyAbout {
                companyAbout
              }
              companyPersonalData {
                companyPersonalData
              }
              companyReturn {
                companyReturn
              }
              companyShipping {
                companyShipping
              }
              corporateName
              phone
              registrationNumber
              sellerId
              isAvailable
            }
          }
        }
        currentSellerProducts: allContentfulFicheProduit(filter: {seller: {sellerId: {eq: $sellerId}}, node_locale: {eq: $locale}}) {
          edges {
            node {
              partnerCategory {
                name
              }
              attributes {
                  goal
                  porosity
                  careSteps
              }
              cataloger
              poids
              seller {
                corporateName
              }
              title
              slug
              childContentfulFicheProduitProductsFieldJsonNode {
                products {
                  gtin
                  id
                  images
                  offers {
                    crossedPriceEndDate
                    crossedPriceStartDate
                    crossedPriceWithVat
                    id
                    isAvailable
                    leadtimeToShip
                    priceWithVat
                    sellerId
                    sellerSku
                  }
                }
              }
              cataloger
              flapSelectionZawema
              principalSveCategory {
                categoryName
                slug
                categoryEncartTitle

              }
              secondarySveCategories {
                categoryName
                slug
              }
            }
            previous {
              slug
            }
          }
        }
        productImages: allContentfulAsset(filter: {node_locale: {eq: $locale}}) {
          edges {
            node {
              title
              file {
                url
              }
              description
              contentful_id
            }
          }
        }
    }
`