const getStyles = (theme) => ({
  filteringSection: {
    flexFlow: "column"
  },
  filtersContainer: {
    borderRight: "1px solid #AFAFAF",
  },
  checkbox: {
    color: "#F5B091",
    outlineOffset: "transparent",
    outline: "transparent",
    borderRadius: "10%"
  }
});

export default getStyles;