import React from 'react';
import DeliveryModesInfo from './DeliveryModesInfo';
import { useSelector } from 'react-redux';
import Loader from '../Loader';

const SellerDeliveryInfo = () => {

  const { deliveryZones, getDeliveryZonesLoading, getDeliveryZonesError, getSellerDeliveryModesLoading, getSellerDeliveryModesError, sellerDeliveryModes } = useSelector((state) => ({
    deliveryZones: state.getIn(["sellerPage", "deliveryZones"]),
    getDeliveryZonesLoading: state.getIn(["sellerPage", "getDeliveryZonesLoading"]),
    getDeliveryZonesError: state.getIn(["sellerPage", "getDeliveryZonesError"]),
    sellerDeliveryModes: state.getIn(["sellerPage", "sellerDeliveryModes"]),
    getSellerDeliveryModesLoading: state.getIn(["sellerPage", "getSellerDeliveryModesLoading"]),
    getSellerDeliveryModesError: state.getIn(["sellerPage", "getSellerDeliveryModesError"])
  }));

  //No response from API
  if (getDeliveryZonesError || getSellerDeliveryModesError)
    return (
      <>
        < div className="px-2 py-6 md:py-10 sm:px-6 max-w-screen-2xl mx-auto text-sm font-medium text-orange-darkest">Livraison
          <p className="mt-1 text-xs text-grey-darkest"> Une erreur est survenue. Merci d'actualiser la page.</p>
        </div>
      </>
    )

  //waiting for the API response
  if (getDeliveryZonesLoading && getSellerDeliveryModesLoading)
    return (
      <>
        < div className="px-2 py-6 md:py-10 sm:px-6 max-w-screen-2xl mx-auto text-sm font-medium text-orange-darkest">Livraison</div>
        <Loader />
      </>
    )

  //we have the deliveryZones and the sellerDelieryInfos
  if (deliveryZones && sellerDeliveryModes)
    return (
      <>

        {/* mobile version */}
        < div className="md:hidden px-4 sm:px-6 text-sm font-medium text-orange-darkest" > Livraison
          {
            deliveryZones.map((deliveryZone) => {
              return (
                <DeliveryModesInfo deliveryZone={deliveryZone} sellerDeliveryModes={sellerDeliveryModes} />
              )
            }
            )
          }
        </div >

        {/* desktop version */}
        < div className="hidden md:block px-2 py-6 md:py-10 sm:px-6 max-w-screen-2xl mx-auto text-sm font-medium text-orange-darkest" > Livraison
          < div className="grid overflow-hidden grid-cols-6 grid-rows-1 px-4 py-5 sm:px-6 border-b border-grey-light" >
            <div className="box flex text-s font-medium text-grey-dark">REGION</div>
            <div className="box flex justify-center text-s font-medium text-grey-dark">DOMICILE STANDARD</div>
            <div className="box flex justify-center text-s font-medium text-grey-dark">DOMICILE EXPRESS</div>
            <div className="box flex justify-center text-s font-medium text-grey-dark">POINT RELAIS</div>
            <div className="box flex justify-center text-s font-medium text-grey-dark">DOMICILE LONG</div>
            <div className="box flex justify-center text-s font-medium text-grey-dark">PAYS CONCERNES</div>
          </div >
          <div className="divide-y divide-grey-light divide-y-[0.5px]">
            {deliveryZones.map((deliveryZone) => {
              return (
                <DeliveryModesInfo deliveryZone={deliveryZone} sellerDeliveryModes={sellerDeliveryModes} />
              )
            }
            )}
          </div>
        </div >

      </>)
}
export default SellerDeliveryInfo;