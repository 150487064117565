import React from 'react';
import { useStyles } from "react-styles-hook";
import getStyles from "./styles";
import * as countries from "i18n-iso-countries";

export const ModalDeliveryCountries = ({ openModalDeliveryCountries, countriesList, hideModal }) => {

  const classes = useStyles(getStyles());

  return (
    openModalDeliveryCountries
    && (
      <>
        <div
          role="button"
          className="overflow-y-auto cursor-auto absolute w-screen h-screen top-0 pt-0 inset-x-0 flex justify-center items-center md:items-center"
          style={classes.modalBackdrop}
          // close modal if anything outside modal content is clicked
          onClick={hideModal}
          onKeyPress={hideModal}
          tabIndex="0"
        >
          <div
            aria-hidden="true"
            className="fixed bg-white w-11/12 lg:w-3/4 h-4/5 overflow-auto sm:h-3/5 flex flex-col justify-center items-center py-4 px-4 md:px-12 md:py-8"
            // don't close modal if anything inside modal content is clicked or any key is pressed
            onClick={(e) => { e.stopPropagation(); }}
            onKeyPress={(e) => { e.stopPropagation(); }}
          >
            <div className="grid overflow-auto grid-cols-1 w-full sm:grid-cols-2 md:grid-cols-4 gap-10">
              {countriesList.map((country) => {
                return (
                  <div className="box">{countries.getName(country, "en")}</div>
                )
              })}
            </div>
          </div >
        </div >
      </>
    ))
};

export default ModalDeliveryCountries;