import React from 'react';

const SellerInfo = ({ seller }) => {

  return (
    <div class="px-4 py-5 sm:px-6">
      <dl class="grid grid-cols-1 gap-x-4 gap-y-6 sm:grid-cols-2">
        <div class="sm:col-span-1">
          <dt class="text-sm font-medium text-orange-darkest">A propos du vendeur</dt>
          <dd class="mt-1 text-xs text-grey-darkest">{seller.companyAbout.companyAbout} </dd>
        </div>
        <div class="sm:col-span-1">
          <dt class="text-sm font-medium text-orange-darkest">Conditions de livraison</dt>
          <dd class="mt-1 text-xs text-grey-darkest">{seller.companyShipping.companyShipping} </dd>
        </div>
        <div class="sm:col-span-1">
          <dt class="text-sm font-medium text-orange-darkest">Gestion des données personnelles</dt>
          <dd class="mt-1 text-xs text-grey-darkest">{seller.companyPersonalData.companyPersonalData}</dd>
        </div>
        <div class="sm:col-span-1">
          <dt class="text-sm font-medium text-orange-darkest">Conditions de retour</dt>
          <dd class="mt-1 text-xs text-grey-darkest">{seller.companyReturn.companyReturn} </dd>
        </div>
      </dl>
      <dl class="mt-12 grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
        <div class="sm:col-span-1">
          <dt class="text-sm font-medium text-orange-darkest">Raison sociale</dt>
          <dd class="mt-1 text-xs text-grey-darkest">{seller.corporateName} </dd>
        </div>
        <div class="sm:col-span-1">
          <dt class="text-sm font-medium text-orange-darkest">N° d'immatriculation</dt>
          <dd class="mt-1 text-xs text-grey-darkest">{seller.registrationNumber}</dd>
        </div>
      </dl>
    </div>
  )
};

export default SellerInfo;