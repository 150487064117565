import React, { useEffect, useState } from 'react';
import ModalDeliveryCountries from '../../ModalDeliveryCountries';
import { Disclosure } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/outline';

const DeliveryModesInfo = ({ sellerDeliveryModes, deliveryZone }) => {
  const deliveryModes = ["domicile-standard", "domicile-express", "relais-colis", "domicile-long"]

  const translateZone = (area) => {
    switch (area) {
      case 'LATIN AMERICA':
        return 'Amérique du Sud';
      case 'AFRICA':
        return 'Afrique';
      case 'METROPOLITAN FRANCE':
        return 'France Métropolitaine, Corse incluse';
      case 'AMERICA':
        return 'Amérique du Nord';
      case 'EU (EXCEPT FRANCE)':
        return 'Europe (hors France)';
      case 'DOM-TOM':
        return 'DOM-TOM';
      default:
        return "zone géographique"
    }
  }


  function classNames(...classes) {
    return classes.filter(Boolean).join('');
  }

  // Modale Delivery Countries
  const [openModalDeliveryCountries, setOpenModalDeliveryCountries] = useState(false);

  let body;
  if (typeof window !== "undefined") { // during build window is unavailable
    body = document.querySelector("body");
  }
  const showModal = () => {
    setOpenModalDeliveryCountries(true);
  };
  const hideModal = () => {
    setOpenModalDeliveryCountries(false);
  };

  useEffect(() => {
    if (openModalDeliveryCountries && typeof window !== "undefined") {
      body.style.overflow = "hidden"; // block scroll on body when menu is open
    };
    if (!openModalDeliveryCountries && typeof window !== "undefined") {
      body.style.overflow = "auto"; // block scroll on body when menu is open
    }
  }, [openModalDeliveryCountries, body.style]);

  return (
    <>
      {/* mobile version */}
      <div className="md:hidden">
        <Disclosure as="div">
          {({ open }) => (
            <>
              <dt className="text-lg">
                <Disclosure.Button className="text-left w-full flex justify-between items-start hover:outline-none focus:outline-none active:outline-none text-grey-darkest">
                  <span className="text-s font-medium text-grey-dark mt-4">{translateZone(deliveryZone.zone)}</span>
                  <span className="ml-6 h-7 flex items-center">
                    <ChevronDownIcon
                      className={classNames(open ? '-rotate-180 mt-6' : 'rotate_0 mt-6', 'h-6 w-6 transform mt-6')}
                      aria-hidden="true" />
                  </span>
                </Disclosure.Button>
              </dt>
              <Disclosure.Panel as="dd" className="mt-2 pr-12">
                <p className="mt-1 text-xs text-grey-darkest">
                  <button className="underline mb-2"
                    onClick={() => showModal()}
                  >
                    Voir les détails
                  </button>
                  < ModalDeliveryCountries countriesList={deliveryZone.countries} openModalDeliveryCountries={openModalDeliveryCountries} hideModal={hideModal} />
                  {
                    deliveryModes.map((deliveryMode) => {
                      return (
                        <>
                          {/* .some checks if the seller delivers in the area received in props */}
                          {sellerDeliveryModes.some((e) => e.deliveryZoneId === deliveryZone.id) ?
                            null
                            :
                            <div className="grid overflow-hidden grid-cols-2 auto-rows-max gap-2 mt-1 text-xs text-grey-darkest">
                              <div>{deliveryMode}</div>
                              <div className="box flex justify-center">X</div></div>}
                          {/* map on the differents delivery modes */}
                          {sellerDeliveryModes.map((sellerDeliveryMode) => {
                            // checking if the seller delivers in the current area with the currnt delivery mode  
                            if (sellerDeliveryMode.deliveryZoneId.includes(deliveryZone.id) && sellerDeliveryMode.deliveryModeId.includes(deliveryMode)) {
                              // checking if the delivery mode is active. If it does, we display yhe prce (except if the price is 0, then we display "offert")
                              if (sellerDeliveryMode.isActive) {
                                return (sellerDeliveryMode.pricePerOfferWithVAT ?
                                  <div className="grid overflow-hidden grid-cols-2 auto-rows-max gap-2 mt-1 text-xs text-grey-darkest">
                                    <div>{deliveryMode}</div>
                                    <div className="box flex justify-center text-orange-dark">{(Number.parseFloat(sellerDeliveryMode.pricePerOfferWithVAT).toFixed(2))} €</div>
                                  </div>
                                  :
                                  <div className="grid overflow-hidden grid-cols-2 auto-rows-max gap-2 mt-1 text-xs">
                                    <div>{deliveryMode}</div>
                                    <div className="box flex justify-center text-orange-dark">offert</div>
                                  </div>)
                              }
                              else {
                                return (
                                  <div className="grid overflow-hidden grid-cols-2 auto-rows-max gap-2 mt-1 text-xs text-grey-darkest">
                                    <div>{deliveryMode}</div>
                                    <div className="box flex justify-center mt-1">X</div>
                                  </div>)
                              }
                            }
                          })
                          }
                        </>
                      )
                    })
                  }
                </p>
              </Disclosure.Panel>
            </>
          )}
        </Disclosure>
      </div>

      {/* desktop version */}
      <div className="hidden md:grid overflow-hidden grid-cols-6 grid-rows-1 px-4 py-5 sm:px-6">
        <div className="box flex text-s font-medium text-grey-dark">{translateZone(deliveryZone.zone)}</div>
        {
          deliveryModes.map((deliveryMode) => {
            return (
              <>
                {/* .some checks if the seller delivers in the area received in props */}
                {sellerDeliveryModes.some((e) => e.deliveryZoneId === deliveryZone.id) ?
                  null
                  :
                  <div className="box flex justify-center mt-1 text-xs text-grey-darkest">X</div>}
                {/* map on the differents delivery modes */}
                {sellerDeliveryModes.map((sellerDeliveryMode) => {
                  // checking if the seller delivers in the current area with the currnt delivery mode  
                  if (sellerDeliveryMode.deliveryZoneId.includes(deliveryZone.id) && sellerDeliveryMode.deliveryModeId.includes(deliveryMode)) {
                    // checking if the delivery mode is active. If it does, we display the price (except if the price is 0, then we display "offert")
                    if (sellerDeliveryMode.isActive) {
                      return (sellerDeliveryMode.pricePerOfferWithVAT ?
                        <div className="box flex justify-center mt-1 text-s text-orange-dark">{(Number.parseFloat(sellerDeliveryMode.pricePerOfferWithVAT).toFixed(2))} €</div>
                        :
                        <div className="box flex justify-center mt-1 text-s text-orange-dark">offert</div>)
                    }
                    else {
                      return (<div className="box flex justify-center mt-1 text-xs text-grey-darkest">X</div>)
                    }
                  }
                }
                )
                }
              </>
            )
          })
        }
        <button
          onClick={() => showModal()}
          className="mt-1 text-s text-grey-darkest underline"
        >
          Voir les détails
        </button>
        <div className="mt-1 text-s text-grey-darkest">
          < ModalDeliveryCountries countriesList={deliveryZone.countries} openModalDeliveryCountries={openModalDeliveryCountries} hideModal={hideModal} />
        </div>
      </div >
    </>
  )
};

export default DeliveryModesInfo;